import logo from './logo.svg';
import './App.css';

import React, { Component, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, withRouter, BrowserRouter } from "react-router-dom";
import { Switch } from "react-router"
import { NavLink, Link, useNavigate } from "react-router-dom"

import Home from './components/pages/Home'
import Pricing from './components/pages/Pricing'
import Search from './components/pages/Search'

function App() {

  const [colors, setColors] = useState({
    "black": "#0D0D0D",
    "blackGrey": "#B5B5B5",
    "white": "#FFFFFF",
    "whiteGrey": "#343434"
  })

  useEffect(() => {
    window.colors = colors;
  },[colors])

  const baseURL = 'https://fontscout.thinetics.io'
  window.baseURL = baseURL;

  function getCurrentTimeFormatted() {
    const now = new Date();

    let hours = now.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
  }

  const getNewAccessToken = async (refreshToken) => {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + refreshToken); 

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }

    requestOptions.headers.set('Authorization', "Bearer " + refreshToken)
  
    fetch(baseURL + '/auth/refresh', requestOptions)
    .then(response => {responseFromServer = response.status; return response.json()})
    .then(result => {
      console.log(result)
      if (result.detail === 'Invalid Token, please logout and log back in.')
      {
        console.log('Bad Refresh Token also!')
        window.location.assign('/')
        return false;
      }
      else
      {
        console.log('Good Refresh Token')
        localStorage.setItem('access_token', result.access_token)
        localStorage.setItem('access_token_expires', result.access_token_expires)
        return true;
      }
    })
  }

  let responseFromServer = '';

  window.apiRequest = (endpoint, requestOptions, operation) => {
    let accessToken = localStorage.getItem('access_token');
    let refreshToken = localStorage.getItem('refresh_token');

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + refreshToken); 
    requestOptions.headers.set('Authorization', "Bearer " + accessToken);
    
    fetch(baseURL + endpoint, requestOptions)
    .then(response => {
        if (!response.ok) {
            if (response.status === 500) {
                return { detail: "Cannot reach server!" };
            }
            throw new Error('Network response was not ok');
        }
        responseFromServer = response.status;
        return response.json();
    })
    .then(result => {
        if (result.detail === 'Invalid Token, please logout and log back in.') {
            console.log('Bad Access Token, getting new one!');
            if (getNewAccessToken(refreshToken)) {
                console.log('continuing operation');
                window.apiRequest(endpoint, requestOptions, operation);
            } else {
                console.log('Did not get new access token');
                window.location.assign('/');
            }
        } else {
            operation(result, responseFromServer);
        }
    })
    .catch(error => {
        console.error('Fetch error:', error);
        operation({ detail: "Cannot reach server!" }, 500);
    });
  }

  window.getServerResponse = (json) => {
    if (typeof json.detail === 'string' || json.detail instanceof String) {
      return (getCurrentTimeFormatted() + ': ' + json.detail)
    } else {
      return json.detail.map((error, index) => {
        return (getCurrentTimeFormatted() + ': ' + error.msg + ' at ' + error.loc.join(', ')).replace(/string/g, "text");
      })
    }
  }

  window.colors = colors;

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Home/>}>

        </Route>
        <Route path='/pricing' exact element={<Pricing/>}>

        </Route>
        <Route path='/search' element={<Search/>}>

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;