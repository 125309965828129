import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Button from '../../elements/Button'
import TextInput from '../../elements/TextInput'

const Main = (props) => {

    console.log(props)

    const [data, setData] = useState({
        email: '',
        password: '',
        confirm_password: '',
    })

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    useEffect(() => {
        // Define the function to run when Enter is pressed
        const handleKeyPress = (event) => {
          if (event.key === 'Enter') {
            props.createAccount(data.email, data.password, data.confirm_password);
          }
        };
    
        // Add event listener for key press
        window.addEventListener('keypress', handleKeyPress);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('keypress', handleKeyPress);
        };
      }, [data]); // Empty dependency array ensures the effect runs only once after initial render

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: window.colors.white, maxWidth: '360px'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Create Account</span>
                <IconButton onClick={() => props.toggleModal(props.index)} img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: window.colors.black, borderRadius: '200px'}} height="28px" width="28px">
                    <div onClick={props.setIsOpen} style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', WebkitAlignItems: 'center', WebkitJustifyContent: 'center'}}>
                        <span style={{color: window.colors.white, fontWeight: '800'}}>X</span>
                    </div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {props.notice ? <span style={{textAlign: 'center'}}>Please create an account to continue using the platform.</span>:''}
                <span style={{fontWeight: '600', marginTop: '20px'}}>Email</span>
                <TextInput onChange={(e) => onDataChange(e, 'email')} Style={{backgroundColor: window.colors.white, border: '2px solid ' + window.colors.black, marginTop: '8px', height: '20px', color: window.colors.black, fontSize: '16px', fontFamily: 'inter'}}>{data.email}</TextInput>
                <span style={{fontWeight: '600', marginTop: '25px'}}>Password</span>
                <TextInput onChange={(e) => onDataChange(e, 'password')} type="password" Style={{backgroundColor: window.colors.white, border: '2px solid ' + window.colors.black, marginTop: '8px', height: '20px', color: window.colors.black, fontSize: '16px', fontFamily: 'inter'}}>{data.password}</TextInput>
                <span style={{fontWeight: '600', marginTop: '25px'}}>Confirm Password</span>
                <TextInput onChange={(e) => onDataChange(e, 'confirm_password')} type="password" Style={{backgroundColor: window.colors.white, border: '2px solid ' + window.colors.black, marginTop: '8px', height: '20px', color: window.colors.black, fontSize: '16px', fontFamily: 'inter'}}>{data.confirm_password}</TextInput>
                <Button onClick={() => props.createAccount(data.email, data.password, data.confirm_password)} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '50px'}}>Create</Button>
            </div>
        </div>
    )
}

export default Main
