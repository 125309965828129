import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { Link, useLocation } from "react-router-dom"

const Main = (props) => {

    const defaultStyle = 
    {
        position: 'absolute',
        backgroundColor: window.colors.black,
        opacity: 0.9,
        height: '100%',
        width: '30%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '2px solid ' + window.colors.white,
        overflow: 'hidden'
    }

    return (
        <motion.div onClick={props.onClick} style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            <motion.div onClick={() => props.toggleModal(localStorage.getItem('username') ? 5:0)} whileHover={{color: window.colors.black, backgroundColor: window.colors.white}} style={{flexGrow: 1, maxHeight: '75px', justifyContent: 'center', alignItems: 'center', textAlign:'center', display: 'flex', cursor: 'pointer'}}>
                <span style={{backgroundColor: window.colors.white, color: window.colors.black, padding: '5px 20px 5px 20px', borderRadius: '100px'}}>{localStorage.getItem('username') ? localStorage.getItem('username'):'Sign In'}</span>
            </motion.div>
            <motion.div onClick={() => props.toggleModal(2)} whileHover={{color: window.colors.black, backgroundColor: window.colors.white}} style={{flexGrow: 1, maxHeight: '75px', justifyContent: 'center', alignItems: 'center', textAlign:'center', display: 'flex', cursor: 'pointer'}}>
                <span>Get Started</span>
            </motion.div>
            <motion.div whileHover={{color: window.colors.black, backgroundColor: window.colors.white}} style={{flexGrow: 1, maxHeight: '75px', justifyContent: 'center', alignItems: 'center', textAlign:'center', display: 'flex', cursor: 'pointer'}}>
                <Link to='/pricing' style={{color: window.colors.white, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', textAlign:'center', display: 'flex'}}>Pricing</Link>
            </motion.div>
            <motion.div onClick={() => props.toggleModal(4)} whileHover={{color: window.colors.black, backgroundColor: window.colors.white}} style={{flexGrow: 1, maxHeight: '75px', justifyContent: 'center', alignItems: 'center', textAlign:'center', display: 'flex', cursor: 'pointer'}}>
                <span>Feedback</span>
            </motion.div>
        </motion.div>
    )
}

export default Main
