import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Button from '../../elements/Button'
import TextInput from '../../elements/TextInput'

const Main = (props) => {

    const [data, setData] = useState({
        username: '',
        password: '',
        remember_me: false
    })

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    const divRef = useRef(null);
    const [isNarrow, setIsNarrow] = useState(false)
  
    useEffect(() => {
        const handleResize = () => {
            if (divRef.current.offsetWidth < 269)
            {
                setIsNarrow(true)
            }
            else
            {
                setIsNarrow(false)
            }
        }
        
        handleResize()

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    },[divRef.current])

    useEffect(() => {
        // Define the function to run when Enter is pressed
        const handleKeyPress = (event) => {
          if (event.key === 'Enter') {
            props.login(data.username, data.password)
          }
        };
    
        // Add event listener for key press
        window.addEventListener('keypress', handleKeyPress);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('keypress', handleKeyPress);
        };
      }, [data]); // Empty dependency array ensures the effect runs only once after initial render

    return (
        <div className='noSelect' style={{width: 'auto', maxWidth: '360px', height: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: window.colors.white}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '32px', flexBasis: '99%'}}>Sign In</span>
                <IconButton onClick={() => props.toggleModal(props.index)} img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: window.colors.black, borderRadius: '200px', marginTop: '7px', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row'}} height="28px" width="28px">
                    <div onClick={props.setIsOpen} style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', WebkitAlignItems: 'center', WebkitJustifyContent: 'center'}}>
                        <span style={{color: window.colors.white, fontWeight: '800'}}>X</span>
                    </div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '30px'}}>
                <span style={{fontWeight: '600'}}>Username</span>
                <TextInput onChange={(e) => onDataChange(e, 'username')} Style={{backgroundColor: window.colors.white, border: '2px solid ' + window.colors.black, marginTop: '8px', height: '20px', color: window.colors.black, fontSize: '16px', fontFamily: 'inter'}}>{data.username}</TextInput>
                <span style={{fontWeight: '600', marginTop: '25px'}}>Password</span>
                <TextInput onChange={(e) => onDataChange(e, 'password')} type='password' Style={{backgroundColor: window.colors.white, border: '2px solid ' + window.colors.black, marginTop: '8px', height: '20px', color: window.colors.black, fontSize: '16px', fontFamily: 'inter'}}>{data.password}</TextInput>
                <div ref={divRef} style={{display: 'flex', flexDirection: props.isNarrow ? 'column':'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', marginRight: '90px'}} onClick={() => onDataChange(!data.remember_me, 'remember_me')}>
                        <div style={{border: '2px solid ' + window.colors.black, width: '16px', height: '16px', backgroundColor: data.remember_me ? window.colors.black:window.colors.white, borderRadius: '5px'}}></div>
                        <span style={{marginLeft: '8px', fontWeight: '600'}}>Remember Me</span>
                    </div>
                    <span onClick={() => {props.toggleModal(props.index); props.toggleModal(1)}} style={{marginLeft: props.isNarrow ? '0px':'auto', marginTop: props.isNarrow ? '10px':'',cursor: 'pointer', fontWeight: '600'}}>Forgot Password</span>
                </div>
                <Button onClick={() => props.login(data.username, data.password, data.remember_me)} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '50px'}}>Log In</Button>
                <Button onClick={() => {props.toggleModal(props.index); props.toggleModal(2)}} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '20px'}}>Create an Account</Button>
            </div>
        </div>
    )
}

export default Main
