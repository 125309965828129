import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import DownloadIcon from '../media/download.png'

import youtube from '../media/youtube.png'

const Main = (props) => {

    const downloadFonts = (fontObj) => {
        // console.log(fontObj)
        // const styles = fontObj.styles;
    
        // styles.forEach(style => {
        //     const styleName = style.style;
        //     const base64Value = style.font;
    
        //     // Extract file extension from styleName
        //     const fileExtension = styleName.split('.').pop();
        //     const fileName = styleName;
    
        //     // Convert base64 to Blob
        //     const binary = atob(base64Value.replace(/\s/g, ''));
        //     const len = binary.length;
        //     const buffer = new ArrayBuffer(len);
        //     const view = new Uint8Array(buffer);
    
        //     for (let i = 0; i < len; i++) {
        //         view[i] = binary.charCodeAt(i);
        //     }
    
        //     const blob = new Blob([view], { type: "application/octet-stream" });
        //     const url = window.URL.createObjectURL(blob);

        //     console.log(blob)
    
        //     // Create a link element and trigger download
        //     const link = document.createElement("a");
        //     link.href = url;
        //     link.download = fileName;
        //     document.body.appendChild(link);
        //     link.click();
    
        //     // Cleanup
        //     document.body.removeChild(link);
        //     window.URL.revokeObjectURL(url);
        // });
        const imageUrl = '../media/youtube.png'; // Replace with your image URL
        const fileName = 'downloaded-image.png';

        fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            console.log(url)
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            // window.URL.revokeObjectURL(url);
            // document.body.removeChild(a);
        })
        .catch(() => alert('Could not download the image'));
    };
    
    return (
        <div style={{display: 'flex', flexDirection: 'row', height: 'auto', borderBottom: '1px solid ' + window.colors.blackGrey, alignItems: 'center', marginTop: '15px', paddingBottom: '20px'}}>
            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', color: window.colors.blackGrey}}>
                    <span style={{fontSize: '24px', fontWeight: '500'}}>{props.font.name}</span>
                    {props.isNarrow2 ? '':<span style={{fontSize: '14px', marginLeft: '15px', marginTop: '9px'}}>{props.font.author}</span>}
                    {props.isNarrow ? '':props.font.categories.map((cat) => { 
                        return <div style={{color: window.colors.black, marginLeft: '10px', backgroundColor: window.colors.white, padding: '4px 10px 4px 10px', borderRadius: '100px', marginTop: '4px', cursor: 'default', fontSize: '12px', fontWeight: '500'}}>{cat}</div>
                    })}    
                </div>
                <div style={{marginTop: '15px'}}>
                    <span style={{fontFamily: props.font.name, fontSize: '20px'}}>The quick brown fox jumps over the lazy dog</span>
                </div>
            </div>  
            <div className='center' style={{width: '130px'}}>
                <IconButton image={DownloadIcon} onClick={() => downloadFonts(props.font)} whileHover={{rotateZ: '10deg', transition: {duration: 0.25}}} Style={{borderRadius: '5px', padding: 0}} size="25px"></IconButton>
            </div>
        </div>
    )
}

export default Main
