import React, { useRef, useEffect, useState } from 'react';
import { motion, useMotionValue, useTransform, useAnimation } from 'framer-motion';

const Slider = (props) => {

    return (
        <div style={{paddingBottom: '10px'}}>
            <input className="slider" onChange={(e) => props.setValue(e.target.value)} value={props.value} type="range" min="100" max="800" step="100"/>
            <style jsx>{`
                .slider {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 100%;
                    height: 2px;
                    background: ${window.colors.black};
                    outline: none;
                    -webkit-transition: .2s;
                    transition: opacity .2s;
                }

                .slider::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 15px;
                    height: 15px;
                    background: ${window.colors.white};
                    border: 2px solid ${window.colors.black};
                    border-radius: 1000px;
                    margin-top: -6px;
                    cursor: pointer;
                }

                .slider::-moz-range-thumb {
                    width: 15px;
                    height: 15px;
                    background: ${window.colors.white};
                    border: 2px solid ${window.colors.black};
                    border-radius: 1000px;
                    cursor: pointer;
                    margin-top: -6px;
                }

                .slider::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 2px;
                    cursor: pointer;
                    border-radius: 5px;
                }

                .slider::-moz-range-track {
                    width: 100%;
                    height: 2px;
                    cursor: pointer;
                    background: #2196F3;
                    border-radius: 5px;
                }
            `}</style>
        </div>
    );
};

export default Slider;
