import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Button from '../../elements/Button'
import TextInput from '../../elements/TextInput'

const Main = (props) => {

    const [data, setData] = useState({
        password: '',
        confirm_password: '',
    })

    const [tryingToUnsub, setTryingToUnsub] = useState(false)

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: window.colors.white, maxWidth: '360px'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Manage User</span>
                <IconButton onClick={() => props.toggleModal(props.index)} img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: window.colors.black, borderRadius: '200px'}} height="28px" width="28px">
                    <div onClick={props.setIsOpen} style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', WebkitAlignItems: 'center', WebkitJustifyContent: 'center'}}>
                        <span style={{color: window.colors.white, fontWeight: '800'}}>X</span>
                    </div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '30px'}}>
                {!tryingToUnsub ? <div>
                    <Button onClick={() => props.logout()} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '20px', width: '250px'}}>Logout</Button>
                    <Button onClick={() => setTryingToUnsub(true)} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '10px', width: '250px'}}>Unsubscribe :(</Button>
                </div>:<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <span>Are you sure you want to unsubscribe?</span>
                    <Button onClick={() => props.unsubscribe()} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '20px', width: '250px'}}>Yes :(</Button>
                    <Button onClick={() => setTryingToUnsub(false)} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '10px', width: '250px'}}>No :)</Button>
                </div>}
            </div>
        </div>
    )
}

export default Main
