import React, { Component, useState, useEffect, useRef, useLayoutEffect, useParams } from 'react'

import { useLocation } from 'react-router-dom';

import PageHeader from '../elements/PageHeader'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'

import DownloadIcon from '../media/download.png'

import SearchItem from '../elements/SearchItem'

import ModalManager from '../elements/ModalManager'
import DownloadFont from '../modals/Search/DownloadFont'
import CreateAccount from '../modals/Home/CreateAccount'

import Notification from '../elements/Notification'

import Loading from '../media/loading.gif'

import JSZip from 'jszip';

const Searching = (props) => {

    const location = useLocation();

    const [searchTrigger, setSearchTrigger] = useState(false)

    const [notice, setNotice] = useState(false)

    const [searchResults, setSearchResults] = useState([])
    const [selectedFont, setSelectedFont] = useState('')

    const [searchBar, setSearchBar] = useState(location.state.searchBar)

    const [isSearching, setIsSearching] = useState(false)

    function getCurrentTimeFormatted() {
        const now = new Date();
    
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
    
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
    
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
      }

    const responseProcessing = async (result, status) => {
        console.log(result);
        if (status === 200) {
            result.forEach(fontObj => {
                fontObj.styles.forEach(style => {
                    const fontName = fontObj.name;
                    const fontStyle = style.style;
                    const fontBase64 = style.font;
                    const fontFaceRule = `
                        @font-face {
                            font-family: '${fontName}';
                            src: url(data:font/truetype;charset=utf-8;base64,${fontBase64}) format('truetype');
                            font-weight: normal;
                            font-style: normal;
                        }
                    `;
        
                    // Create a new style element
                    const styleElement = document.createElement('style');
                    styleElement.type = 'text/css';
                    if (styleElement.styleSheet) {
                        styleElement.styleSheet.cssText = fontFaceRule;
                    } else {
                        styleElement.appendChild(document.createTextNode(fontFaceRule));
                    }
        
                    // Append the style element to the head
                    document.head.appendChild(styleElement);
                });
            });
            setSearchResults(result);
        } else {
            let response = window.getServerResponse(result)
            console.log(response)
            if (result.detail.includes('reached the maximum'))
            {
                if (localStorage.getItem('premium') === 'false')
                {
                    setNotification(getCurrentTimeFormatted() + ': Please purchase a plan at pricing.');
                }
                else
                {
                    setNotification(getCurrentTimeFormatted() + ': You have reached the maximum amount of free searches! Please register for an account.');
                    setNotice(true)
                    toggleModal(1)
                }
            }
            else
            {
                setNotification(window.getServerResponse(result));
            }
        }
        
        setIsSearching(false);
    }

    const createAccount = (email, password, passwordConfirm) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                email: email,
                password: password,
            })
        };

        if (password === passwordConfirm)
        {
            window.apiRequest('/user', requestOptions, (result, status) => {   
                if (status === 200)
                {
                     toggleModal(2)
                     setNotification('Account Created, an email has been sent to your email to verify your account.')
                } else {setNotification(window.getServerResponse(result))}
            })
        }
        else
        {
            setNotification('Passwords do not match!')
        }
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                search_term: searchBar
            })
        };

        const fetchFonts = async () => {
            setIsSearching(true);

            window.apiRequest(localStorage.getItem('premium') === 'true' ? '/font/search':'/font/free-search', requestOptions, async (result, status) => {
                responseProcessing(result, status)
            });
        };

        fetchFonts();
    }, [searchTrigger]);

    const [modalManager, setModalManager] = useState([false, false])

    const toggleModal = (index) => {
        setModalManager((prevModalManager) =>
            prevModalManager.map((item, idx) => (idx === index ? !item : item))
        );
    };

    const [isNarrow, setIsNarrow] = useState(false);
    const [isNarrow2, setIsNarrow2] = useState(false)
    const divRef = useRef(null);
  
    //CSS triggers for responsiveness
    useEffect(() => {
        const handleResize = () => {
            console.log(divRef.current.offsetWidth)
            if (divRef.current.offsetWidth < 1000)
            {
                setIsNarrow(true)
            }
            else
            {
                setIsNarrow(false)
            }

            if (divRef.current.offsetWidth < 550)
            {
                setIsNarrow2(true)
            }
            else
            {
                setIsNarrow2(false)
            }
        }
        
        handleResize()

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    },[divRef.current])

    const loadGoogleFonts = async (fonts) => {
        const head = document.head;
        const validFonts = [];

        console.log('Google Fonts - ' + fonts)
    
        for (const font of fonts) {
            const fontUrl = `https://fonts.googleapis.com/css2?family=${font.replace(/ /g, '+')}&display=swap`;
    
            try {
                const response = await fetch(fontUrl);
    
                if (response.status === 200) {
                    const link = document.createElement('link');
                    link.href = fontUrl;
                    link.rel = 'stylesheet';
                    head.appendChild(link);
                    validFonts.push(font);
                } else if (response.status === 400) {
                    console.error(`Font "${font}" does not exist.`);
                }
            } catch (error) {
                console.error(`Error loading font "${font}":`, error);
            }
        }
        console.log(validFonts)
        return validFonts;
    };
    
    const loadFontSquirrelFonts = async (fonts) => {
        console.log('squirrel fonts - ' + fonts);
        const validFonts = [];
        
        for (const fontName of fonts) {
            try {
                const response = await fetch(`https://www.fontsquirrel.com/fonts/download/${fontName}`);
                if (!response.ok) {
                    throw new Error(`Network response was not ok for font: ${fontName}`);
                }
                const zipData = await response.arrayBuffer();
                const zip = await JSZip.loadAsync(zipData);
                const ttfFiles = Object.keys(zip.files).filter(fileName => fileName.endsWith('.ttf'));
    
                for (const ttfFile of ttfFiles) {
                    const fileData = await zip.file(ttfFile).async('arraybuffer');
                    const font = new FontFace(fontName, fileData);
                    await font.load();
                    document.fonts.add(font);
                }
                validFonts.push(fontName);
            } catch (error) {
                console.error('Error loading font:', fontName, error);
            }
        }
        
        document.body.style.fontFamily = validFonts.join(', ');
        return validFonts;
    };

    const [notification, setNotification] = useState('')

    useEffect(() => {
        if (isSearching) {
            const interval = setInterval(() => {
                setDots(prevDots => (prevDots + 1) % 4);
            }, 500);
            return () => clearInterval(interval);
        }
    }, [isSearching]);

    const [dots, setDots] = useState(0)

    return (
        <div ref={divRef} style={{width: '100vw', height: '100vh', backgroundColor: window.colors.black, overflow: 'hidden'}}>
            <Notification message={notification}></Notification>
            <div style={{width: '70%', height: '100%', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column', color: window.colors.white}}>
                <PageHeader searchTerm={location.state.searchBar} submitSearch={() => isSearching ? '':setSearchTrigger((searchTrigger) => !searchTrigger)} searchBar={searchBar} setSearchBar={setSearchBar}></PageHeader>
                <span style={{marginTop: '20px', color: window.colors.blackGrey}}>
                    {isSearching ? `Finding the best fonts for you${'.'.repeat(dots)}` : `${searchResults.length} Matched Results`}
                </span>
                <div className="scrollableDiv" style={{overflowY: 'scroll', paddingRight: '30px'}}>
                    {isSearching ? '':searchResults.map((font, index) => {
                        return <SearchItem key={index} font={font} isNarrow={isNarrow} isNarrow2={isNarrow2} openDownloadModal={() => {toggleModal(0); setSelectedFont(font)}}></SearchItem>
                    })}
                </div>
            </div> 
            <ModalManager modalStates={modalManager} setModalStates={setModalManager}>  
                <DownloadFont selectedFont={selectedFont} toggleModal={toggleModal} setNotification={setNotification} index={0}></DownloadFont>
                <CreateAccount toggleModal={toggleModal} isNarrow={isNarrow} createAccount={createAccount} index={1} notice={notice}></CreateAccount>
            </ModalManager>

            <style>
                {`
                    .scrollableDiv::-webkit-scrollbar {
                        width: 5px;
                    }
                    .scrollableDiv::-webkit-scrollbar-track {
                        background: ${window.colors.whiteGrey};
                        border-radius: 1000px;
                    }
                    .scrollableDiv::-webkit-scrollbar-thumb {
                        background: ${window.colors.white};
                        border-radius: 1000px;
                    }
                `}
            </style>


        </div>
    )
}

export default Searching
