import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            onClick={function()} - passthrought to onClick to callback a function - empty by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
            img={true/false} - enables if it should be image or text
    */}

    const defaultStyle = 
    {
        backgroundColor: window.colors.white,
        border: '1px solid #D7D7D7',
        color: window.colors.black,
        fontWeight: '500',
        height: props.height,
        width: props.width
    }

    return (
        <motion.button className='center' whileHover={props.whileHover} onClick={props.onClick} style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            {props.image !== undefined ? <img style={{width: props.width === undefined ? props.size:props.width, height: props.height === undefined ? props.size:props.height, padding: '2px'}} src={props.image}></img>:props.children}
        </motion.button>
    )
}

export default Main

