import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Button from '../../elements/Button'
import TextInput from '../../elements/TextInput'

const Main = (props) => {

    const [data, setData] = useState({
        email: '',
        feedback: '',
    })

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    useEffect(() => {
        // Define the function to run when Enter is pressed
        const handleKeyPress = (event) => {
          if (event.key === 'Enter') {
            props.submitFeedback(data.email, data.feedback)
          }
        };
    
        // Add event listener for key press
        window.addEventListener('keypress', handleKeyPress);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('keypress', handleKeyPress);
        };
      }, [data]); // Empty dependency array ensures the effect runs only once after initial render

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: window.colors.white, maxWidth: '360px'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Feedback</span>
                <IconButton onClick={() => props.toggleModal(props.index)} img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: window.colors.black, borderRadius: '200px'}} height="28px" width="28px">
                    <div onClick={props.setIsOpen} style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', WebkitAlignItems: 'center', WebkitJustifyContent: 'center'}}>
                        <span style={{color: window.colors.white, fontWeight: '800'}}>X</span>
                    </div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '30px'}}>
                <span style={{fontWeight: '600'}}>Email</span>
                <TextInput onChange={(e) => onDataChange(e, 'email')} Style={{backgroundColor: window.colors.white, border: '2px solid ' + window.colors.black, marginTop: '8px', height: '20px', color: window.colors.black, fontSize: '16px', fontFamily: 'inter'}}>{data.email}</TextInput>
                <span style={{fontWeight: '600', marginTop: '25px'}}>Feedback</span>
                <textarea onChange={(e) => onDataChange(e.target.value, 'feedback')} style={{backgroundColor: window.colors.white, border: '2px solid ' + window.colors.black, marginTop: '8px', height: '20px', color: window.colors.black, fontSize: '16px', fontFamily: 'inter', borderRadius: '5px', height: '150px'}}>{data.feedback}</textarea>
                <Button onClick={() => props.submitFeedback(data.email, data.feedback)} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '50px'}}>Submit</Button>
            </div>
        </div>
    )
}

export default Main
