import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { Link, useNavigate } from "react-router-dom"

import PageHeader from '../elements/PageHeader'
import TextInput from '../elements/TextInput'
import IconButton from '../elements/IconButton'

import linkedin from '../media/linkedin.png'
import youtube from '../media/youtube.png'
import tiktok from '../media/tiktok.png'
import twitter from '../media/twitter.png'
import link from '../media/link.png'

const Home = (props) => {

    const [searchBar, setSearchBar] = useState('')

    const placeholders = [
        'A font you would use for my new energy drink.',
        'A font that conveys the idea of strength.',
        'A font for my new tech websites logo.',
        'A font that is just outright strange.',
      ];

    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const [blink, setBlink] = useState(true);

    useEffect(() => {
        const currentPlaceholder = placeholders[index];
    
        const type = () => {
          if (!isDeleting) {
            if (currentCharIndex < currentPlaceholder.length) {
              setDisplayedText(currentPlaceholder.slice(0, currentCharIndex + 1));
              setCurrentCharIndex(currentCharIndex + 1);
            } else {
              setTimeout(() => setIsDeleting(true), 2000); // Pause before deleting
            }
          } else {
            if (currentCharIndex > 0) {
              setDisplayedText(currentPlaceholder.slice(0, currentCharIndex - 1));
              setCurrentCharIndex(currentCharIndex - 1);
            } else {
              setIsDeleting(false);
              setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
              setCurrentCharIndex(0);
            }
          }
        };
    
        const typingInterval = setInterval(type, isDeleting ? 30 : 20);
    
        return () => clearInterval(typingInterval);
    }, [currentCharIndex, isDeleting, index]);

    useEffect(() => {
        const blinkInterval = setInterval(() => {
          setBlink((prev) => !prev);
        }, 500);
        return () => clearInterval(blinkInterval);
      }, []);

    const navigate = useNavigate()

    const submitSearch = (searchTerm) => {
        navigate('/search', {state: {searchBar}})
    }

    return (
        <div style={{width: '100vw', height: '100vh', backgroundColor: window.colors.black, overflow: 'hidden', }}>
            <div style={{width: '70%', height: '100%', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column'}}>
                <PageHeader></PageHeader>
                <div style={{flexGrow: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '15%', textAlign:'center'}}>
                    <span style={{color: window.colors.white, fontSize: '40px'}}>Search fonts by context</span>
                    <span style={{color: window.colors.blackGrey, fontSize: '20px', marginTop: '10px'}}>Let AI do the rest</span>
                    <div style={{border: '3px solid ' + window.colors.blackGrey, borderRadius: '500px', width: 'calc(100% - 25px)', maxWidth: '700px', height: '40px', marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <TextInput onChange={(e) => setSearchBar(e)} onKeyDown={(e) => e.key === 'Enter' ? submitSearch(searchBar):''} placeholder={`${displayedText}${blink ? '|' : ' '}`} Style={{width: '93%', height: '100%', marginLeft: '0px', border: 0, backgroundColor: 'RGBA(0,0,0,0)', fontFamily: 'inter'}}>{searchBar}</TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    {/* <a href='https://www.linkedin.com/company/thinetics-software' target="blank"><IconButton image={linkedin} whileHover={{translateY: '-5px'}} size='30px' Style={{backgroundColor: window.colors.black, border: 'none'}}></IconButton></a>
                    <a href='https://thinetics.io' target="blank"><IconButton image={youtube} whileHover={{translateY: '-5px'}} size='30px' Style={{backgroundColor: window.colors.black, border: 'none'}}></IconButton></a>
                    <a href='https://thinetics.io' target="blank"><IconButton image={tiktok} whileHover={{translateY: '-5px'}} size='30px' Style={{backgroundColor: window.colors.black, border: 'none'}}></IconButton></a>
                    <a href='https://thinetics.io' target="blank"><IconButton image={twitter} whileHover={{translateY: '-5px'}} size='30px' Style={{backgroundColor: window.colors.black, border: 'none'}}></IconButton></a>
                    <a href='https://thinetics.io' target="blank"><IconButton image={link} whileHover={{translateY: '-5px'}} size='30px' Style={{backgroundColor: window.colors.black, border: 'none'}}></IconButton></a> */}
                </div>  
            </div>
        </div>
    )
}

export default Home
