import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { Link, useLocation } from "react-router-dom"

import Button from '../elements/Button'

import SignIn from '../modals/Home/SignIn'
import ForgotPassword from '../modals/Home/ForgotPassword'
import CreateAccount from '../modals/Home/CreateAccount'
import ResetPassword from '../modals/Home/ResetPassword'
import Feedback from '../modals/Home/Feedback'
import ManageUser from '../modals/Home/ManageUser'

import ModalManager from '../elements/ModalManager'

import Hamburger from '../media/hamburger.png'

import TextInput from '../elements/TextInput'
import IconButton from '../elements/IconButton'
import PopupSidebar from '../elements/PopupSidebar'

import Notification from '../elements/Notification'

const Home = (props) => {

    const [modalManager, setModalManager] = useState([false, false, false, false, false, false])

    const [searchBar, setSearchBar] = useState(props.searchTerm)

    const toggleModal = (index) => {
        setModalManager((prevModalManager) =>
            prevModalManager.map((item, idx) => (idx === index ? !item : item))
        );
    };

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();

    const location = useLocation()

    const [isNarrow, setIsNarrow] = useState(false);
    const [isNarrow2, setIsNarrow2] = useState(false);

    const [mobileMenu, setMobileMenu] = useState(false)

    const divRef = useRef(null);

    const [resetPasswordKey, setResetPasswordKey] = useState('')

    //for triggering reset password modal
    useEffect(() => {
        const id = query.get('id');
        setResetPasswordKey(query.get('key'))

        if (id === 'reset-password')
        {
            toggleModal(3)
        }
    },[])
    
    //remember me handling
    window.addEventListener('beforeunload', () => {
        if (localStorage.getItem('remember' !== 'true'))
        {
            localStorage.clear();
        }
    });

    //for setting users info
    useEffect(() => {
        if (localStorage.getItem('username') !== null && localStorage.getItem('premium') === null)
        {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }

            window.apiRequest('/user/me', requestOptions, (result, status) => {   
                if (status === 200)
                {
                    localStorage.setItem('premium', result.role === 'premium' ? true:false)
                } else {}
            })
        }
    },[])
  
    useEffect(() => {
        const handleResize = () => {
            if (divRef.current.offsetWidth < 850)
            {
                setIsNarrow(true)
            }
            else
            {
                setIsNarrow(false)
            }

            if (divRef.current.offsetWidth < 800)
                {
                    setIsNarrow2(true)
                }
                else
                {
                    setIsNarrow2(false)
                }
        }
        
        handleResize()

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    },[divRef.current])

    //THIS METHOD IS DUPLICATED IN SEARCH, IF YOU UPDATE THIS, YOU MUST UPDATE BOTH
    const createAccount = (email, password, passwordConfirm) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                email: email,
                password: password,
            })
        };

        if (password === passwordConfirm)
        {
            window.apiRequest('/user', requestOptions, (result, status) => {   
                if (status === 200)
                {
                     toggleModal(2)
                     setNotification('Account Created, an email has been sent to your email to verify your account.')
                } else {setNotification(window.getServerResponse(result))}
            })
        }
        else
        {
            setNotification('Passwords do not match!')
        }
    }

    const [notification, setNotification] = useState('')

    const submitRequest = (email) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                email: email,
            })
        }

        window.apiRequest('/auth/forgot_password', requestOptions, (result, status) => {   
            if (status === 200)
            {
                toggleModal(1)
                setNotification('If this email exists, you will have received an email to reset your password.')
            } else {setNotification(window.getServerResponse(result))}
        })
    }

    const resetPassword = (password, passwordConfirm, key) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                password: password,
            })
        }

        if (password !== passwordConfirm)
        {
            setNotification('Password do not match!')
            
        }
        else
        {
            window.apiRequest('/auth/reset_password/' + key, requestOptions, (result, status) => {   
                if (status === 200)
                {
                    toggleModal(1)
                    setNotification('Password changed successfully!')
                    window.location.assign('/')
                } else {setNotification(window.getServerResponse(result))}
            })
        }
    }

    const submitFeedback = (email, feedback) => {

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                email: email,
                feedback: feedback,
            })
        }

        window.apiRequest('/feedback', requestOptions, (result, status) => {   
            if (status === 200)
            {
                toggleModal(4)
                setNotification('Feedback Submitted! Thank you!')
            } else {setNotification(window.getServerResponse(result))}
        })
    }

    const login = (username, password, remember_me) => {
        const formData = new URLSearchParams();
        formData.append("username", username);
        formData.append("password", password)

        fetch(window.baseURL + '/auth/login', {
            method: "POST", 
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
            },
            body: formData
        })
        .then(res => {
            if (res.ok) { // res.ok is true if status is 200-299
                return res.json().then(json => {
                    localStorage.clear();
                    localStorage.setItem('access_token', json.access_token);
                    localStorage.setItem('refresh_token', json.refresh_token);
                    localStorage.setItem('access_token_expires', json.access_token_expires);
                    localStorage.setItem('refresh_token_expires', json.refresh_token_expires);
                    localStorage.setItem('username', username)
                    localStorage.setItem('remember', remember_me)
                    // Add code to run if status is 200
                    window.location.href = '/'
                });
            } else {
                // Add code to run if status is not 200
                return res.json().then(json => {
                    setNotification(window.getServerResponse(json))
                });
            }
        })
    }

    const logout = () => {
        localStorage.clear()
        window.location.href = '/'
    }

    const unsubscribe = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        }

        window.apiRequest('/payment/subscription', requestOptions, (result, status) => {   
            if (status === 200)
            {
                toggleModal(5)
                setNotification('Successfully unsubscribed! :(')
            } else {setNotification(window.getServerResponse(result))}
        })
    }

    const [fontState, setFontState] = useState('Inter')

    return (
        <div ref={divRef} style={{width: '100%', height: '125px', minHeight: '125px', backgroundColor: window.colors.black, overflow: 'hidden', display: 'flex', flexDirection: 'row', color: 'white'}}>
            <Notification message={notification}></Notification>
            <div style={{flexGrow: 1, alignItems: 'center', display: 'flex'}}>
                <Link to='/'><span style={{fontSize: '40px', color: window.colors.white, fontFamily: 'allura'}}>FontScout.io</span></Link>
            </div>
            <div style={{flexGrow: 3, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                {location.pathname === '/search' ? isNarrow2 ? '':<div style={{border: '3px solid ' + window.colors.blackGrey, borderRadius: '500px', width: '100%', height: '40px', marginTop: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <TextInput onChange={(e) => props.setSearchBar(e)} onKeyDown={(e) => e.key === 'Enter' ? props.submitSearch(searchBar):''} placeholder={'A font you would use for...'} Style={{width: '93%', height: '100%', marginLeft: '0px', border: 0, backgroundColor: 'RGBA(0,0,0,0)', fontFamily: 'inter'}}>{props.searchBar}</TextInput>
                </div>:''}
            </div>
            {isNarrow ? 
                <IconButton image={Hamburger} onClick={() => setMobileMenu((mobileMenu) => !mobileMenu)} size={'35px'} Style={{backgroundColor: window.colors.black, border: 'none'}}></IconButton>
            :
            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center'}}>  
                <Button Style={{height: '30px', backgroundColor: window.colors.black, color: window.colors.white, marginRight: '10px', minWidth: '115px'}} onClick={() => toggleModal(4)}>Feedback</Button>
                <Link to='/pricing'><Button Style={{height: '30px', backgroundColor: window.colors.black, color: window.colors.white}}>Pricing</Button></Link>
                <Button Style={{height: '30px', backgroundColor: window.colors.black, color: window.colors.white, marginRight: '10px', minWidth: '115px'}} onClick={() => toggleModal(2)}>Get Started</Button>
                <Button Style={{height: '30px', minWidth: '85px'}} onClick={() => toggleModal(localStorage.getItem('username') ? 5:0)}>{localStorage.getItem('username') ? localStorage.getItem('username'):'Sign In'}</Button>
            </div>
            }
            <ModalManager modalStates={modalManager} setModalStates={setModalManager}>
                <SignIn toggleModal={toggleModal} isNarrow={isNarrow} login={login} index={0}></SignIn>
                <ForgotPassword toggleModal={toggleModal} isNarrow={isNarrow} submitRequest={submitRequest} index={1}></ForgotPassword>
                <CreateAccount toggleModal={toggleModal} isNarrow={isNarrow} createAccount={createAccount} index={2}></CreateAccount>
                <ResetPassword toggleModal={toggleModal} isNarrow={isNarrow} resetPassword={resetPassword} resetPasswordKey={resetPasswordKey} index={3}></ResetPassword>
                <Feedback toggleModal={toggleModal} isNarrow={isNarrow} submitFeedback={submitFeedback} index={4}></Feedback>
                <ManageUser toggleModal={toggleModal} isNarrow={isNarrow} logout={logout} unsubscribe={unsubscribe} index={5}></ManageUser>
            </ModalManager>
            {mobileMenu ? <PopupSidebar toggleModal={toggleModal}></PopupSidebar>:''}
        </div>
    )
}

export default Home
