import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

const Main = (props) => {

    const defaultStyle = 
    {
        display: 'block',
        backgroundColor: window.colors.white,
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '10000px',
        color: window.colors.black,
        fontWeight: '500',
        height: '40px'
    }

    return (
        <motion.button onClick={props.onClick} whileHover={{scale: 1.03}} style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            <span>{props.children}</span>
        </motion.button>
    )
}

export default Main
