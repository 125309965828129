import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import PageHeader from '../elements/PageHeader'
import Button from '../elements/Button'

import ModalManager from '../elements/ModalManager'
import CreateAccount from '../modals/Home/CreateAccount'

import Notification from '../elements/Notification'

const Pricing = (props) => {

    const [modalManager, setModalManager] = useState([false])

    const toggleModal = (index) => {
        setModalManager((prevModalManager) =>
            prevModalManager.map((item, idx) => (idx === index ? !item : item))
        );
    };

    const [isNarrow, setIsNarrow] = useState(false);
    const divRef = useRef(null);

    function getCurrentTimeFormatted() {
        const now = new Date();
    
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
    
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
    
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
      }
  
    useEffect(() => {
        const handleResize = () => {
            console.log(divRef.current.offsetWidth)
            if (divRef.current.offsetWidth < 1210)
            {
                setIsNarrow(true)
            }
            else
            {
                setIsNarrow(false)
            }
        }
        
        handleResize()

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    },[divRef.current])

    const createAccount = (email, password, passwordConfirm) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                email: email,
                password: password,
            })
        };

        if (password === passwordConfirm)
        {
            window.apiRequest('/user', requestOptions, (result, status) => {   
                if (status === 200)
                {
                     toggleModal(0)
                     setNotification('Account Created, an email has been sent to your email to verify your account.')
                } else {setNotification(window.getServerResponse(result))}
            })
        }
        else
        {
            setNotification('Passwords do not match!')
        }
    }

    const [notification, setNotification] = useState('')

    const pricingButtonTrigger = () => {
        if (localStorage.getItem('access_token') === null)
        {
            toggleModal(0)
        }
        else
        {
            if (localStorage.getItem('premium') === 'true')
            {
                setNotification(getCurrentTimeFormatted() + ': You are already a premium user!')
            }
            else
            {
                getStripeLink()
            }
        }
    }

    const getStripeLink = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        };

        window.apiRequest('/payment/subscription', requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200)
            {
                window.open(result.product_link, '_blank', 'noopener,noreferrer')
            } else {setNotification(window.getServerResponse(result))}
        })
    }

    return (
        <div ref={divRef} style={{width: '100vw', height: '100vh', backgroundColor: window.colors.black, overflow: 'hidden', overflow: 'auto'}}>
            <Notification message={notification}></Notification>
            <div style={{width: '70%', height: '100%', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column'}}>
                <PageHeader></PageHeader>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: isNarrow ? 'column':'row', paddingBottom: '150px', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{backgroundColor: window.colors.white, borderRadius: '25px', color: window.colors.black, display: 'flex', flexDirection: 'column', padding: '30px 35px 30px 35px', marginRight: isNarrow ? '0px':'60px'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <span style={{fontSize: '36px'}}>Basic</span>
                            <div style={{marginLeft: 'auto', backgroundColor: window.colors.black, width: '28px', height: '28px', borderRadius: '1000px', marginTop: '10px'}}></div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                            <span style={{fontSize: '36px', fontWeight: '600'}}>$5</span>
                            <span style={{marginTop: '17px', marginLeft: '10px', fontWeight: '500', color: window.colors.whiteGrey}}>/ month</span>
                        </div>
                        <div className='center' style={{borderTop: '2px solid ' + window.colors.black, borderBottom: '2px solid ' + window.colors.black, paddingTop: '30px', paddingBottom: '30px', marginTop: '20px'}}>
                            <span style={{fontSize: '16px', color: window.colors.black, fontWeight: '600'}}>For people with commitment issues</span>
                        </div>
                        <ul style={{padding: '20px', fontWeight: '600'}}>
                            <li>Unlimited Searches</li>
                            <li style={{marginTop: '15px'}}>Unlimited Downloads</li>
                            <li style={{marginTop: '15px'}}>Easy Integration with Adobe, Figma & Sketch</li>
                            <li style={{marginTop: '15px'}}>Access to over 1000+ Fonts</li>
                            <li style={{marginTop: '15px'}}>Real Time Font Previews</li>
                            <li style={{marginTop: '15px'}}>Fast Responses</li>
                            <li style={{marginTop: '15px'}}>High Quality Results</li>
                        </ul>
                        <Button onClick={() => {pricingButtonTrigger()}} Style={{backgroundColor: window.colors.black, color: window.colors.white, borderRadius: '10px', height: '50px', fontSize: '18px', marginTop: '10px'}}>Get Started</Button>
                    </div>
                    <div style={{border: '5px solid ' + window.colors.white, borderRadius: '25px', color: window.colors.white, display: 'flex', flexDirection: 'column', padding: '30px 35px 30px 35px', marginLeft: isNarrow ? '0px':'60px', marginTop: isNarrow ? '30px':'0px'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <span style={{fontSize: '36px'}}>Advanced</span>
                            <div style={{marginLeft: 'auto', backgroundColor: window.colors.white, width: '28px', height: '28px', borderRadius: '1000px', marginTop: '10px'}}></div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                            <span style={{fontSize: '36px', fontWeight: '600'}}>$129</span>
                            <span style={{marginTop: '17px', marginLeft: '10px', fontWeight: '500', color: window.colors.blackGrey}}>One Time Payment</span>
                        </div>
                        <div className='center' style={{borderTop: '2px solid ' + window.colors.white, borderBottom: '2px solid ' + window.colors.white, paddingTop: '30px', paddingBottom: '30px', marginTop: '20px'}}>
                            <span style={{fontSize: '16px', color: window.colors.white, fontWeight: '500'}}>For people who know what they want</span>
                        </div>
                        <ul style={{padding: '20px', fontWeight: '500'}}>
                            <li>Unlimited Searches</li>
                            <li style={{marginTop: '15px'}}>Unlimited Downloads</li>
                            <li style={{marginTop: '15px'}}>Easy Integration with Adobe, Figma & Sketch</li>
                            <li style={{marginTop: '15px'}}>Access to over 1000+ Fonts</li>
                            <li style={{marginTop: '15px'}}>Real Time Font Previews</li>
                            <li style={{marginTop: '15px'}}>Fast Responses</li>
                            <li style={{marginTop: '15px'}}>High Quality Results</li>
                        </ul>
                        <Button onClick={() => {pricingButtonTrigger()}} Style={{backgroundColor: window.colors.white, color: window.colors.black, borderRadius: '10px', height: '50px', fontWeight: '700', fontSize: '18px', marginTop: '10px'}}>Get Started</Button>
                    </div>
                </div>
            </div>
            <ModalManager modalStates={modalManager} setModalStates={setModalManager}>
                <CreateAccount toggleModal={toggleModal} index={0} createAccount={createAccount}></CreateAccount>
            </ModalManager>
        </div>
    )
}

export default Pricing
