import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

const Main = (props) => {

    const toggleModal = (index) => {
        props.setModalStates((prevModalManager) =>
            prevModalManager.map((item, idx) => (idx === index ? !item : item))
        );
    };

    return (
        <>
            {props.children && (Array.isArray(props.children) ? props.children : [props.children]).map((modal, index) => (
                <Modal
                    key={index}
                    style={{
                        overlay: { backgroundColor: 'rgba(255,255,255,0.05)' },
                        content: {
                            top: '40%',
                            left: '50%',
                            bottom: 'auto',
                            right: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: 'auto',
                            height: 'auto',
                            maxHeight: '90vh',
                            borderRadius: '20px',
                            border: '2px solid ' + window.colors.black
                        }
                    }}
                    isOpen={props.modalStates[index]}
                    onRequestClose={() => toggleModal(index)}
                >
                    {modal}
                </Modal>
            ))}
        </>
    )
}

export default Main
