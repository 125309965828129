import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

const Main = (props) => {

    const defaultStyle = 
    {
        display: 'block',
        backgroundColor: props.isDisabled ? window.colors.white:window.colors.black,
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '6px',
        paddingBottom: '6px',
        borderRadius: '5px',
        border: '1px solid ' + window.colors.blackGrey,
        color: props.isDisabled ? window.colors.black:window.colors.white,
        fontWeight: '500',
        position: 'relative',
        width: props.width,
        cursor: props.isDisabled ? 'default':'auto'
    }

    return (
        <motion.input 
            value={(props.prefix === undefined ? '':props.prefix) + props.children + (props.suffix === undefined ? '':props.suffix)} 
            onChange={(e) => props.isDisabled === true ? '':props.onChange(e.target.value.replace(props.prefix, '').replace(props.suffix, ''), props.index, props.indexParent)} 
            onClick={props.onClick} 
            type={props.type} 
            style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            >
        </motion.input>
    )
}

export default Main
