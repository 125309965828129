import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";

const Main = (props) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if (props.message) {
            const id = new Date().getTime(); // Use timestamp as a unique ID
            const newNotification = {
                id,
                message: props.message,
                hovering: false
            };
            setNotifications(prev => [...prev, newNotification]);

            // Set a timeout to automatically remove this notification after 3 seconds
            const timer = setTimeout(() => {
                removeNotification(id);
            }, 3000);
            
            // Store the timer ID in the notification object
            newNotification.timer = timer;
        }
    }, [props.message]); // Dependency only on props.message

    const removeNotification = (id) => {
        setNotifications(prev => prev.filter(notification => notification.id !== id));
    };

    const handleHoverStart = (id) => {
        setNotifications(prev => prev.map(notification => 
            notification.id === id ? { ...notification, hovering: true } : notification
        ));
        // Clear the timer
        const notification = notifications.find(notification => notification.id === id);
        clearTimeout(notification.timer);
    };

    const handleHoverEnd = (id) => {
        setNotifications(prev => prev.map(notification => 
            notification.id === id ? { ...notification, hovering: false } : notification
        ));
        // Set a new timer
        const timer = setTimeout(() => {
            removeNotification(id);
        }, 3000);
        // Update the timer ID in the notification object
        setNotifications(prev => prev.map(notification => 
            notification.id === id ? { ...notification, timer } : notification
        ));
    };

    return (
        <AnimatePresence>
            {notifications.map((notification, index) => (
                <motion.div 
                    key={notification.id}
                    initial={{ marginTop: '-15vh' }}
                    animate={{ marginTop: `calc(0vh - ${index * -5}vh)` }}
                    exit={{ marginTop: '-15vh' }}
                    transition={{ type: 'spring', stiffness: 260, damping: 20 }}
                    style={{
                        position: 'absolute',
                        top: '10%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'auto',
                        height: 'auto',
                        padding: '5px 10px',
                        backgroundColor: window.colors.white,
                        color: window.colors.black,
                        border: '2px solid ' + window.colors.black,
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 999
                    }}
                    onHoverStart={() => handleHoverStart(notification.id)}
                    onHoverEnd={() => handleHoverEnd(notification.id)}
                >
                    <span style={{ fontWeight: '500' }}>{notification.message}</span>
                </motion.div>
            ))}
        </AnimatePresence>
    );
}

export default Main;
