import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Slider from '../../elements/Slider'
import Button from '../../elements/Button'

const Main = (props) => {

    const [fontWeight, setFontWeight] = useState(400)
    const [italic, setItalic] = useState(false)

    const getCurrentSystemTime = () => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    const downloadGoogleFont = (fontName, fontWeight, isItalic) => {
        const getCurrentSystemTime = () => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        };
    
        const baseUrl = 'https://fonts.googleapis.com/css2';
        const fontStyle = isItalic ? 'ital,wght' : 'wght';
        const fontNameFormatted = fontName.replace(/\s+/g, '+');
        const fontWeightFormatted = isItalic ? `1,${fontWeight}` : fontWeight;
        const url = `${baseUrl}?family=${fontNameFormatted}:${fontStyle}@${fontWeightFormatted}&display=swap`;
    
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    // Handle non-200 responses without throwing an error
                    props.setNotification(getCurrentSystemTime() + ": Specific font style does not exist. Try changing the weight or italic values.");
                    return Promise.reject(new Error('Specific font style does not exist'));
                }
                return response.text();
            })
            .then(css => {
                const fontUrlMatch = css.match(/url\((https:\/\/fonts.gstatic.com\/[^)]+)\)/);
                if (fontUrlMatch && fontUrlMatch[1]) {
                    const fontUrl = fontUrlMatch[1];
                    const link = document.createElement('a');
                    link.href = fontUrl;
                    link.download = `${fontNameFormatted}-${fontWeight}${isItalic ? 'Italic' : ''}.woff2`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    // Handle the case where font URL is not found in CSS
                    props.setNotification(getCurrentSystemTime() + ": Font URL not found in CSS.");
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // Set notification if an error occurs
                props.setNotification(getCurrentSystemTime() + ": Specific font style does not exist. Try changing the weight or italic values.");
            });
    };

    const downloadFontSquirrelFont = (fontName, fontWeight, isItalic) => {
        const getCurrentSystemTime = () => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        };
    
        const baseUrl = 'https://www.fontsquirrel.com/fontfacekit';
        const fontNameFormatted = fontName.replace(/\s+/g, '-').toLowerCase();
        const fileName = `${fontNameFormatted}-${fontWeight}${isItalic ? 'Italic' : ''}.zip`;
        const url = `${baseUrl}/${fontNameFormatted}`;
    
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    // Handle non-200 responses without throwing an error
                    console.error(`Font "${fontName}" download failed.`);
                    return Promise.reject(new Error('Font download failed'));
                }
                return response.blob();
            })
            .then(blob => {
                // Create a temporary link element to trigger download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                // Clean up
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle error and set notification
                props.setNotification(getCurrentSystemTime() + ': Font download failed.');
            });
    };

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: window.colors.white}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%', fontSize: '32px'}}>Download</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: window.colors.black, borderRadius: '200px', marginTop: '7px'}} height="28px" width="28px">
                    <div onClick={props.setIsOpen} style={{}}><span onClick={() => props.toggleModal(props.index)} style={{color: window.colors.white, fontWeight: '800'}}>X</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{marginTop: '30px', fontWeight: '700', fontSize: '24px'}}>{props.selectedFont.name}</span>
                <span style={{marginTop: '5px', fontWeight: '500', color: window.colors.whiteGrey}}>{props.selectedFont.author}</span>
                <span style={{marginTop: '30px', fontWeight: '500'}}>Font Weight</span>
                <span style={{marginTop: '5px', color: window.colors.whiteGrey, fontWeight: '600'}}>{fontWeight}</span>
                <Slider value={fontWeight} setValue={setFontWeight}></Slider>
                <div className='noSelect' style={{marginTop: '10px', cursor: 'pointer', display: 'flex', flexDirection: 'row'}}>
                    <div onClick={() => setItalic((italic) => !italic)} style={{width: '15px', height: '15px', borderRadius: '5px', border: '2px solid ' + window.colors.black, backgroundColor: italic ? window.colors.black:window.colors.white}}></div>
                    <span style={{marginLeft: '10px', fontWeight: '500'}}>Italic</span>
                </div>
                <Button onClick={() => downloadGoogleFont(props.selectedFont.name, fontWeight, italic)} Style={{backgroundColor: window.colors.black, color: window.colors.white, marginTop: '30px'}}>Download</Button>
            </div>
        </div>
    )
}

export default Main
